import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    triggerClose = new Subject<void>();
    constructor(private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    ) { }


  register(data: any){
    return this.http.post(`${environment.API_URL}/users/register`, data);
  }

  sendOtp(data: any){
    return this.http.post(`${environment.API_URL}/users/forgotPassword`, data);
  }

  resetPassword(data: any){
    return this.http.post(`${environment.API_URL}/users/resetPassword`, data);
  }

  updatePassword(data: any){
    return this.http.put(`${environment.API_URL}/users/changePassword`, data);
  }

  // Login request
  login(credentials: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/users/login`, credentials);
  }

  /**
   * Logout
   */
  public logout() {
    const data ={
      token : localStorage.getItem('budgetToken')
    }
    localStorage.clear();
    localStorage.removeItem('budgetToken');
    this.toastr.success('Logout successfully', 'Success!');
    this.router.navigate(["authentication/login"]);
    // return this.http.post(`${environment.API_URL}/logout`, data)
    // .subscribe((res) => {
    // this.router.navigate(["/login"]);
    // localStorage.clear();
    // localStorage.removeItem('budgetToken');
    // this.toastr.success('Logout successfully', 'Success!');
    //       // window.location.reload();
    // })
  }

  // login status
  get isLoggedIn(): boolean {
    return !!localStorage.getItem('budgetToken');
  }
  // return true if Token present in local storage [for AuthGuard]
  public loggedIn() {
    return !!localStorage.getItem('budgetToken');
  }

  getToken() {
    const token = localStorage.getItem('budgetToken');
    return token ? token : null;
  }

  /**
     * Clear the local storage
     */
  clearLocalStorage() {
    localStorage.clear();
  }

  getProfile(): Observable<any> {
    return this.http.get(`${environment.API_URL}/users/profile`);
  }

  updateProfile(profile: any): Observable<any> {
    return this.http.put(`${environment.API_URL}/users/profile`,profile);
  }

  closeSideMenu() {
    this.triggerClose.next();
  }

  calculatePaginationVal(): any {
    const height = (window.innerHeight - 290);
    if (window.innerHeight <= 500) {
      return 4;
    }
    if (window.innerHeight <= 600) {
      return 4;
    }
    if (window.innerHeight <= 657) {
      return 5;
    }
    /* 80% */
    if (window.innerHeight <= 730) {
      return 6;
    }
    /* 80% */
    if (window.innerHeight <= 821) {
      return 9;
    }
    /* 75% */
    if (window.innerHeight <= 900) {
      return 10;
    }
    /* 67% */
    if (window.innerHeight <= 970) {
      return 12;
    }
    /* 67% */
    if (window.innerHeight <= 1020) {
      return 13;
    }
    /* 67% */
    if (window.innerHeight <= 1100) {
      return 13;
    }
    /* 67% */
    if (window.innerHeight <= 1170) {
      return 13;
    }
    /* 67% */
    if (window.innerHeight <= 1210) {
      return 14;
    }
    /* 67% */
    if (window.innerHeight <= 1250) {
      return 14;
    }
    /* 67% */
    if (window.innerHeight <= 1350) {
      return 14;
    }
    /* 50% */
    if (window.innerHeight <= 1400) {
      return 14;
    }
    /* 50% */
    if (window.innerHeight <= 1450) {
      return 15;
    }
    if (window.innerHeight <= 1550) {
      return 15;
    }
    /* 50% */
    if (window.innerHeight <= 1600) {
      return 15;
    }
    /* 50% */
    if (window.innerHeight <= 1700) {
      return 16;
    }
    /* 50% */
    if (window.innerHeight <= 1750) {
      return 16;
    }
    /* 33.3% */
    if (window.innerHeight <= 1800) {
      return 17;
    }
    /* 25% */
    if (window.innerHeight <= 2628) {
      return 17;
    }
  }

}
