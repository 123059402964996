<div class="menu-styler" [ngClass]="{ open: styleSelectorToggle }">
  <div class="pct-c-btn">
    <a href="javascript:" (click)="styleSelectorToggle = !styleSelectorToggle"></a>
  </div>
  <div class="style-block">
    <h4 class="mb-2">Customizer</h4>
    <hr class="my-0" />

    <div class="card border mt-3">
      <div class="card-header">
        <h5>Font Family</h5>
      </div>
      <div class="card-body">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            checked
            [ngClass]="{
              active: setFontFamily === 'Roboto'
            }"
            (click)="setfontFamily('Roboto')"
            data-value="Roboto"
          />
          <label class="form-check-label" for="flexRadioDefault1"> Roboto </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            [ngClass]="{
              active: setFontFamily === 'poppins'
            }"
            (click)="setfontFamily('poppins')"
            data-value="poppins"
          />
          <label class="form-check-label" for="flexRadioDefault2"> Poppins </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault3"
            [ngClass]="{
              active: setFontFamily === 'inter'
            }"
            (click)="setfontFamily('inter')"
            data-value="inter"
          />
          <label class="form-check-label" for="flexRadioDefault3"> Inter </label>
        </div>
      </div>
    </div>
  </div></div
>
