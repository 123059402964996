import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  icon?: string;
  url?: string;
  classes?: string;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const NavigationItems = [
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      // {
      //   id: 'default',
      //   title: 'Dashboard',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: '/dashboard',
      //   icon: 'ti ti-dashboard',
      //   breadcrumbs: false
      // },
      {
        id: 'project',
        title: 'Project',
        type: 'collapse',
        icon: 'ti ti-vocabulary',
        children: [
          {
            id: 'project-list',
            title: 'Project List',
            type: 'item',
            url: '/project-list',
            breadcrumbs: false
          },
          {
            id: 'project-financial',
            title: 'Financial',
            type: 'item',
            url: '/project-financial',
            breadcrumbs: false
          },
          {
            id: 'payment-calendar',
            title: 'Payment Calendar',
            type: 'item',
            url: '/payment-calendar',
            breadcrumbs: false
          },
        ]
      }
    ],
  },
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
