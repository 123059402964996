<li data-username="Budget Tool dashboard" class="nav-item coded-hasmenu" [routerLinkActive]="['active']">
  <a [routerLinkActive]="['active']" href="javascript:" class="nav-link" #collapseButton (click)="navCollapse($event)">
    @if (item.icon) {
      <span class="coded-micon">
        <i class="{{ item.icon }}"></i>
      </span>
    }
    <span class="coded-mtext">{{ item.title }} </span>
  </a>
  <ul class="coded-submenu" [routerLinkActive]="['active']">
    @for (item of item.children; track item) {
      @if (item.type == 'group') {
        <app-nav-group [item]="item"></app-nav-group>
      }
      @if (item.type == 'collapse') {
        <app-nav-collapse [item]="item"></app-nav-collapse>
      }
      @if (item.type == 'item') {
        <app-nav-item [item]="item"></app-nav-item>
      }
    }
  </ul>
</li>
