import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { NotFoundComponent } from './theme/shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  },
  {
    path: 'authentication',
    redirectTo: '/authentication/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/project-list',
        pathMatch: 'full'
      },
      // {
      //   path: 'dashboard',
      //   loadComponent: () => import('./demo/dashboard/default.component'),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'project-list',
        loadComponent: () => import('./theme/layout/admin/components/project-list/project-list.component').then(c => c.ProjectListComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'project-financial',
        loadComponent: () => import('./theme/layout/admin/components/project-financial/project-financial.component').then(c => c.ProjectFinancialComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-calendar',
        loadComponent: () => import('./theme/layout/admin/components/payment-calendar/payment-calendar.component').then(c => c.PaymentCalendarComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        loadComponent: () => import('./theme/layout/admin/components/my-profile/my-profile.component').then(c => c.MyProfileComponent),
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
        canActivate: [LoggedInGuard],
      }
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
