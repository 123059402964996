<div class="card" [ngClass]="className">
  <div class="card-header">
    @if (!customHeader) {
    <div class="d-flex justify-content-between">
      <h3>{{ cardTitle }}</h3>
      @if (customButton) {
        <button type="button" class="btn btn-secondary" [disabled]="buttonDisable" (click)="buttonClick.emit()">{{customButton}}</button>
      }
    </div>
    }
  </div>
  <div class="card-block" [ngClass]="cardTitle === 'Project List' ? 'pt-3':''">
    <ng-content></ng-content>
  </div>
</div>