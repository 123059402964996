// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// project import
import { CardComponent } from './components/card/card.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

// bootstrap import
import { NgbDropdownModule, NgbNavModule, NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { NumericOnlyDirective } from './directives/numbersOnly-directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrencyShortenPipe } from './directives/currency-shorten.pipe';
import { NumericInputDirective } from './directives/numeric-input-directive';
import { CustomCurrencyPipe } from './pipes/inr-currency-pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    BreadcrumbsComponent,
    NgbDropdownModule,
    NgbNavModule,
    NgbModule,
    NgbCollapseModule,
    NgScrollbarModule,
    NgxPaginationModule,
    NgxSpinnerModule.forRoot({ type: 'ball-atom' }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    BreadcrumbsComponent,
    SpinnerComponent,
    NgbModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbCollapseModule,
    NgScrollbarModule,
    NgxPaginationModule,
    NumericOnlyDirective,
    NgxSpinnerModule,
    CurrencyShortenPipe,
    NumericInputDirective,
    CustomCurrencyPipe
  ],
  declarations: [SpinnerComponent,NumericOnlyDirective,CurrencyShortenPipe,NumericInputDirective,CustomCurrencyPipe]
})
export class SharedModule {}
