import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    if (value.length === 1 && value === '0') {
      return; // Allow '0' as the first character
    }

    // Remove leading zeros if more than one character
    let sanitizedValue = value;
    if (sanitizedValue.length > 1 && sanitizedValue[0] === '0' && sanitizedValue[1] !== '.') {
      sanitizedValue = sanitizedValue.replace(/^0+/, '');
    }

    // Ensure valid numeric input
    const validNumberRegex = /^[0-9]*\.?[0-9]*$/;
    if (!validNumberRegex.test(sanitizedValue)) {
      sanitizedValue = sanitizedValue.replace(/[^\d.-]/g, '');
    }
    
    // Update the control value
    this.control.control?.setValue(sanitizedValue);
  }
}
