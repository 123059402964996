<div class="wrapper">
    <div class="landing-page">
      <div class="logo-part text-center mb-5">
          <img src="assets/images/logo.png" width="250">
      </div>

      <h1> 404</h1>
      <h2> Page Not Found</h2>
      <p> We can't find the page you're looking for.</p>
      <button class="btn btn-secondary" (click)="toBack()">Go Back</button>
    </div>
  </div> 