import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyShorten'
})
export class CurrencyShortenPipe implements PipeTransform {
  transform(value: number | string): string {
    // Convert value to number if it's a string
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Define the thresholds for shortening the value
    const thresholds = [
      { value: 1e9, symbol: 'B' }, // Billion
      { value: 1e6, symbol: 'M' }, // Million
      // { value: 1e5, symbol: 'Lakh' }, // Lakh
      { value: 1e3, symbol: 'K' }  // Thousand
    ];

    // Find the appropriate threshold and format the value accordingly
    const threshold = thresholds.find(t => numericValue >= t.value);
    if (threshold) {
      return '$'+(numericValue / threshold.value).toFixed(1) + threshold.symbol;
    } else {
      // If the value is smaller than the smallest threshold, return it as is
      return '$'+numericValue.toLocaleString();
    }
  }
}
