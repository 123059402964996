<!-- [ Sidebar Menu ] start -->
<nav>
  <div class="navbar-wrapper">
    <div class="m-header justify-content-between">
      <!-- [ Header Topbar ] start -->
      <a [routerLink]="['/dashboard/']" class="b-brand">
        <!-- ========   change your logo hear   ============ -->
        <img src="assets/images/logo.png" width="130" class="logo logo-lg" />
      </a>
      <a type="button" class="btn custom-style" (click)="closeMenu()"><i class="fa fa-times" aria-hidden="true"></i></a>
    </div>
    <app-nav-content (NavCollapsedMob)="navCollapseMob()" class="scroll-div w-100"></app-nav-content>
  </div>
</nav>
<!-- [ Sidebar Menu ] end -->
