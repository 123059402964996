// Angular import
import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent {
  greeting: string = '';
  userDetails: any;
  constructor(
    private authService: AuthService,
  ) { 
    this.setGreeting();
    this.userDetails = JSON.parse(localStorage.getItem('loggedUserData'));
  }
  logOut(){
    this.authService.logout();
  }

  setGreeting(): void {
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      this.greeting = 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 17) {
      this.greeting = 'Good Afternoon';
    } else {
      this.greeting = 'Good Evening';
    }
  }

  openMenu(){
    this.userDetails = JSON.parse(localStorage.getItem('loggedUserData'));
  }
}
